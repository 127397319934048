import {css} from "lit";

export const sbkMetaNavItemsStyle = css`
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
      height: 100%;
    margin: 0;
    padding: 0;
    gap: var(--sbkTopNavBarSpacingLargeScreenGap);
  }
`;
