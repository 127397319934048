import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const sbkMetaNavStyle = css`
    :host {
        --meta-nav__color: var(--_meta-nav__color, var(--sbkTopNavBarFgColorOnLightEnabled));
        --meta-nav__bg: var(--_meta-nav__bg, var(--sbkTopNavBarBgColorOnLightDefault));
    }

    .meta-nav {
        display: flex;
        height: var(--sbkTopNavBarSizingLargeScreenFixedBarHeight);
        flex-wrap: wrap;
        justify-content: space-around;
        background-color: var(--meta-nav__bg);
        color: var(--meta-nav__color);
        align-items: flex-end;
        z-index: 10;
        @media all and (max-width: ${breakpointXl}) {
            display: none;
            height: var(--sbkTopNavBarSizingSmallScreenFixedBarHeight);
        }
    }

    .meta-nav__wrapper {
        display: flex;
        width: 100%;
        max-width: var(--sbkCoreContentAreaMaxWidth);
        justify-content: space-between;
        align-items: flex-end;
        margin: 0;
        padding: 0 var(--sbkCoreGridSmMargin);

        @media all and (min-width: ${breakpointMd}) {
            padding: 0 var(--sbkCoreGridMdMargin);
        }

        @media all and (min-width: ${breakpointLg}) {
            padding: 0 var(--sbkCoreGridLgMargin);
        }

        @media all and (min-width: ${breakpointXl}) {
            margin: 0 auto;
            padding: 0 var(--sbkCoreGridXlMargin);
        }
    }

    .meta-nav__wrapper-items {
        display: flex
    }

    .meta-nav-items {
        display: flex;
        align-items: flex-end;
    }

    .meta-nav-icons {
        display: flex;
        height: 34px;
    }

    .verticalDivider {
        margin-left: var(--sbkTopNavBarSpacingLargeScreenGap);
        margin-right: var(--sbkTopNavBarSpacingLargeScreenGap);
        border-left: var(--sbkTopNavBarBorderRadiusDivider) solid var(--sbkTopNavBarFgColorOnLightEnabled);
        height: var(--sbkTopNavBarSizingDividerHeight);
    }
`;
