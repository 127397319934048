import { customElement } from 'lit/decorators.js';
import { sbkMetaNavStyle } from './meta-nav.styles';
import {html, LitElement} from 'lit';


@customElement('sbk-meta-nav')
export class SbkMetaNav extends LitElement {
    static get styles() {
        return sbkMetaNavStyle;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('keydown', this._handleKeyDown);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    private _handleKeyDown = (event: KeyboardEvent) => {
        const focusableElements = Array.from(this.querySelectorAll('sbk-tab-menu-item')) as HTMLElement[];

        if (!focusableElements.length) return;

        const activeElement = document.activeElement as HTMLElement;
        const currentIndex = focusableElements.indexOf(activeElement);
        if (currentIndex === -1) return;

        let newIndex;
        if (event.key === 'ArrowRight') {
            newIndex = (currentIndex + 1) % focusableElements.length;
        } else if (event.key === 'ArrowLeft') {
            newIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length;
        } else {
            return;
        }

        event.preventDefault();
        const focusElement = focusableElements[newIndex].shadowRoot?.firstElementChild?.firstElementChild as HTMLElement;
        focusElement?.focus();
    };



    render() {
        return html`
            <div class="meta-nav">
                <div class="meta-nav__wrapper">
                    <div class="meta-nav__wrapper-items">
                        <div class="meta-nav-items">
                            <slot></slot>
                        </div>
                        <div class="meta-nav-icons">
                            <span class="verticalDivider"></span>
                            <slot name="icons"></slot>
                        </div>
                    </div>
                    <div class="meta-nav-cta">
                        <slot name="cta"></slot>
                    </div>
                </div>
            </div>
        `;
    }
}
